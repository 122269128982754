import React from "react";
import { SimpleGrid } from "@mantine/core";
import { IntroductionCard, IntroductionCardWithButton } from "./Cards";

// Card Icons
import { GiHand } from "react-icons/gi";
import { FaPlane } from "react-icons/fa";
import { AiOutlineTeam } from "react-icons/ai";
import { checkColor } from "../../../../utils/theme";
import { Home, PlanePrivate, UserMultiple } from "@carbon/icons-react";

const CardDisplay = () => {
  return (
    <>
      <SimpleGrid
        cols={3}
        spacing="xl"
        mt={50}
        mb={50}
        breakpoints={[{ maxWidth: "md", cols: 1 }]}
      >
        <IntroductionCardWithButton
          icon={<Home size={"32"} color={checkColor()} />}
          title="Welcome to IVAO Flight Operations Department"
          description={
            <>
              The <b>Flight Operations Team</b> is in charge of:
              <br />
              <br />
              <ul className="list-disc ml-10">
                <li>
                  The <b>Virtual Airlines</b> policy, management and
                  certification process
                </li>
                <li>
                  Maintain <b>databases</b> up-to-date (Airlines, Aircraft,
                  Routes)
                </li>
                <li>
                  <b>Provide Support</b> for other departments when needed, such
                  as providing routes, or any help related to flight operations
                </li>
              </ul>
              <br />
              We also provide support to all divisional Flight Operation Teams
              <br />
              <br />
            </>
          }
          buttons={[
            {
              buttonText: "Contact Flight Operations Department",
              buttonLink: "mailto:flightops@ivao.aero",
            },
            {
              buttonText: "Our Team",
              buttonLink: "https://www.ivao.aero/staff/department.asp?Id=f",
            },
          ]}
        />

        <IntroductionCardWithButton
          icon={<PlanePrivate size={32} color={checkColor()} />}
          title="What is a Virtual Airline (VA)?"
          description={
            <>
              A <b>Virtual Airline (VA)</b> is a dedicated hobby organization
              whose aim is to simulate the flights and the traffic to model the
              operations of an airline. <b>Virtual Airlines</b> have a presence
              on the internet, like a real airline. Some virtual airlines cover
              countries, or continents, while some operate internationally.
              <br />
            </>
          }
          buttons={[
            {
              buttonText: "List All Virtual Airlines",
              buttonLink: "/virtualairlines/list",
              internal: true,
            },
            {
              buttonText: "Virtual Airline Requirements",
              buttonLink:
                "https://wiki.ivao.aero/en/home/ivao/regulations#requirements",
            },
            {
              buttonText: "Register my Virtual Airline",
              buttonLink:
                "https://wiki.ivao.aero/en/home/flightoperations/FAQ_VA#how-do-i-request-certification-for-my-new-virtual-airline",
            },
          ]}
        />

        <IntroductionCard
          icon={<UserMultiple size={32} color={checkColor()} />}
          title="Team Structure"
          description={
            <>
              <b>Operational Advisors</b>
              <br />
              <br />
              <ul className="list-disc ml-10">
                <li>Virtual Airline Management and Certification</li>
                <li>Routine Checks (for non-divisional virtual airlines).</li>
                <li>Virtual Airline Entertainment</li>
                <li>Divisional relationship</li>
                <li>Standards Monitoring</li>
              </ul>
              <br />
              <br />
              <b>Planning Advisors</b>
              <br />
              <br />
              <ul className="list-disc ml-10">
                <li>
                  Provide briefings and routes to Events Department, World Tour
                  Department, ATC Ops, Special Operations Department and
                  Training Department
                </li>
                <li>
                  Monitor the creation of Airport Briefings (TBC) with divisions
                </li>
                <li>Divisional relationship</li>
                <li>Standards Monitoring</li>
              </ul>
            </>
          }
        />
      </SimpleGrid>
    </>
  );
};

export default CardDisplay;
