import React from "react";
import { useMantineColorScheme } from "@mantine/core";

const Footer = () => {
  const { colorScheme } = useMantineColorScheme();
  return (
    <footer className="mb-10">
      <img
        src={
          colorScheme === "light"
            ? "https://static.ivao.aero/img/logos/logo.svg"
            : "https://static.ivao.aero/img/logos/logo_white.svg"
        }
      />
    </footer>
  );
};

export default Footer;
