import { useAuth as __useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  const auth = __useAuth();
  const navigate = useNavigate();

  auth.startSilentRenew();

  if (auth.error) {
    console.error(auth.error)
  }

  const signIn = () => {
    let redirect = window.location.href.substring(window.location.origin.length)
    if (redirect === '/logout' || redirect === 'logout') redirect = '/'
    window.sessionStorage.setItem('redirect', redirect)
    auth.signinRedirect();
  }

  const hasRedirect = () => window.sessionStorage.getItem('redirect');

  const redirect = () => {
    const redirectUrl = window.sessionStorage.getItem('redirect');
    if (!redirectUrl) {
      return
    }
    window.sessionStorage.removeItem('redirect')
    navigate(redirectUrl)
  }

  return { ...auth, signIn, hasRedirect, redirect };
}