import React from "react";
import { Header } from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import VirtualAirlinesTable from "../components/VirtualAirlinesTable";

const VirtualAirlinesListing = () => {
  return (
    <>
      <Header />
      <VirtualAirlinesTable />
      <Footer />
    </>
  );
};

export default VirtualAirlinesListing;
