import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { GeneralLayout } from "@ivaoaero/front-components";
import VirtualAirlinesListing from "./pages/VirtualAirlinesListing";

export const CoreRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <GeneralLayout>
            <Home />
          </GeneralLayout>
        }
      />
      <Route path="/virtualairlines/list" element={
        <GeneralLayout>
          <VirtualAirlinesListing />
        </GeneralLayout>
      } />
    </Routes>
  );
};
