import {
  Button,
  useMantineColorScheme,
  Switch,
  Group,
  NavLink,
  Box,
} from "@mantine/core";
import { useAuth, useUser } from "../../../auth";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const auth = useAuth();
  const { user } = useUser();
  const navigate = useNavigate();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  return (
    <Box className="w-full mt-10">
      {auth.isAuthenticated ? (
        <Group position="apart">
          <img
            className="cursor-pointer"
            onClick={() => navigate("/")}
            src={
              colorScheme === "light"
                ? "https://static.ivao.aero/img/logos/logo.svg"
                : "https://static.ivao.aero/img/logos/logo_white.svg"
            }
          />
          <Group position="left">
            {/* <NavLink label="Home" onClick={() => navigate('/')} /> */}
            <NavLink label={`${user.firstName} ${user.lastName} (${user.id})`}>
              <NavLink label="Logout" onClick={() => auth.signoutRedirect()} />
              <NavLink label="Settings">
                <Switch
                  label="Dark Mode"
                  checked={colorScheme === "dark"}
                  onClick={() => toggleColorScheme()}
                />
              </NavLink>
            </NavLink>
          </Group>
        </Group>
      ) : (
        <Group position="apart">
          <img
          className="cursor-pointer"
            onClick={() => navigate("/")}
            src={
              colorScheme === "light"
                ? "https://static.ivao.aero/img/logos/logo.svg"
                : "https://static.ivao.aero/img/logos/logo_white.svg"
            }
          />
          <Group>
            <Button
              className={`${
                colorScheme === "dark"
                  ? "bg-white text-black"
                  : "bg-blue-800 text-white"
              }`}
              color={"ivao.3"}
              onClick={() => navigate('/')}
            >
              Home
            </Button>
            <Button
              className={`${
                colorScheme === "dark"
                  ? "bg-white text-black"
                  : "bg-blue-800 text-white"
              }`}
              color={"ivao.3"}
              onClick={() => auth.signIn()}
            >
              Login
            </Button>
            <Switch
                  label="Dark Mode"
                  checked={colorScheme === "dark"}
                  onClick={() => toggleColorScheme()}
                />
          </Group>
        </Group>
      )}
    </Box>
  );
};
