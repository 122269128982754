import { VirtualAirlineDto } from "../../modules/core/types/virtual-airline.type";
import { useQuery, useInfiniteQuery } from "react-query";
import {
  getPaginatedVirtualAirlines,
} from "../ApiRequests";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";

const useGetAllVAs = () => {
  const fetchPaginatedVirtualAirlines = async ({ pageParam = 1 }) => {
    return await getPaginatedVirtualAirlines(pageParam);
  };

  return useInfiniteQuery({
    queryKey: ["virtual_airlines"],
    queryFn: fetchPaginatedVirtualAirlines,
    getNextPageParam: (lastPage, pages) => lastPage.page + 1,
    retry(failureCount) {
      return failureCount <= 5;
    },
    onError: () => {
      showNotification({
        title: "Error!",
        message: "I could not fetch data.",
        color: "red",
        styles: { title: { fontWeight: "bold" } },
      });
    },
  });
};

export default useGetAllVAs;
