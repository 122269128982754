import React, { useEffect, useState } from "react";
import {
  Table,
  Badge,
  ScrollArea,
  Text,
  Modal,
  Image,
  Button,
} from "@mantine/core";
import {
  PaginatedVirtualAirlineDto,
  VirtualAirlineDto,
} from "../../types/virtual-airline.type";
import useGetAllVAs from "../../../../utils/hooks/useGetAllVAs";
import { getAirlineLogo } from "../../../../utils/ApiRequests";
import { PulseLoader } from "@ivaoaero/front-components";
import { useInView } from "react-intersection-observer";

// Notifications
import { showNotification } from "@mantine/notifications";
import { useAuth, useUser } from "../../../auth";
import { InfiniteData } from "react-query";

const processData = (
  data: InfiniteData<PaginatedVirtualAirlineDto> | undefined,
  isSuccess: boolean
) => {
  if (!isSuccess) return;
  const finalArray: VirtualAirlineDto[] = [];

  data?.pages.map((page) =>
    page.items.map((va) => {
      finalArray.push(va);
    })
  );
  finalArray?.sort((a, b) => a.name.localeCompare(b.name));

  return finalArray;
};

const VirtualAirlinesTable = () => {
  // Infinite Scroll
  const { ref, inView } = useInView();
  // Data
  const {
    data,
    isSuccess,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useGetAllVAs();
  const finalData = processData(data, isSuccess);
  const auth = useAuth();
  const { user } = useUser();
  // Single airline display
  const [modalOpened, setModalOpened] = useState(false);
  const [modalData, setModalData] = useState<VirtualAirlineDto | null>();
  const [modalImage, setModalImage] = useState<any>(null);
  // Process data when success on fetching
  useEffect(() => {
    processData(data, isSuccess);
  }, [isSuccess]);

  // Infinite scroll logic
  useEffect(() => {
    if (inView) fetchNextPage();
  }, [inView]);

  const rows = finalData?.map((virtualAirline) => (
    <tr key={virtualAirline.id}>
      <td
        className="cursor-pointer"
        onClick={() => handleNameClick(virtualAirline.id)}
      >
        <Text>{virtualAirline.name}</Text>
      </td>
      <td>
        <Text
          td={"underline"}
          className="cursor-pointer"
          c="blue"
          onClick={() => (window.location.href = virtualAirline.website)}
        >
          {virtualAirline.website}
        </Text>
      </td>
      <td>{virtualAirline.airline.icao}</td>
      <td>{virtualAirline.airline.iata}</td>
      <td>{virtualAirline.divisionId}</td>
      <td>
        <Badge color={virtualAirline.airline.generalAviation ? "green" : "red"}>
          {virtualAirline.airline.generalAviation ? "True" : "False"}
        </Badge>
      </td>
    </tr>
  ));

  const handleNameClick = async (id: number) => {
    await setModalData(finalData?.find((airline) => airline.id === id));
    getAirlineLogo(JSON.stringify(id))
      .then((blob) => {
        setModalImage(blob);
        if (typeof blob === "string") setModalImage(blob);
        else {
          let reader = new FileReader();
          reader.onloadend = function () {
            setModalImage(this.result);
          };
          reader.readAsDataURL(blob);
        }
      })
      .catch((err) =>
        showNotification({
          title: "Error!",
          message: "I could not load the airline logo.",
          color: "red",
          styles: { title: { fontWeight: "bold" } },
        })
      );
    setModalOpened(true);
  };

  if (!data) {
    return (
      <div className="grid w-full h-screen items-center justify-center">
        <PulseLoader />
      </div>
    );
  }

  return (
    <>
      <ScrollArea>
        <Modal
          opened={modalOpened}
          onClose={async () => {
            await setModalOpened(false);
            setModalImage(null);
            setModalData(null);
          }}
          title={`Details for ${modalData?.name}`}
        >
          <div className="flex flex-col items-center">
            {auth.isAuthenticated && user.isStaff ? (
              // If user is staff and is authenticated, link to data website
              <Text
                weight={500}
                size={"lg"}
                color="blue"
                className="cursor-pointer underline"
                component="a"
                href={`https://data.ivao.aero/virtualairlines/info/${modalData?.id}`}
              >
                {modalData?.name} - {modalData?.id}
              </Text>
            ) : (
              <Text weight={500} size={"lg"}>
                {modalData?.name}
              </Text>
            )}
            <Image src={modalImage} />
            <br />
            {/* Codes */}
            <div className="flex justify-between flex-row w-full">
              <div className="flex flex-col items-center justify-center">
                <Text weight={700}>ICAO:</Text>
                <Text size={"sm"}>{modalData?.airline.icao}</Text>
              </div>
              <div className="flex flex-col items-center justify-center">
                <Text weight={700}>IATA:</Text>
                <Text size={"sm"}>
                  {modalData?.airline.iata ? (
                    modalData.airline.iata
                  ) : (
                    <Badge color="red">No IATA available</Badge>
                  )}
                </Text>
              </div>
              <div className="flex flex-col items-center justify-center">
                <Text weight={700}>Callsign</Text>
                <Text size={"sm"}>
                  {modalData?.airline.callsign.toLocaleUpperCase()}
                </Text>
              </div>
            </div>
            <br />
            {/* Website & Status */}
            <div className="flex justify-between items-center flex-row w-full">
              <div className="flex flex-col items-center justify-center">
                <Text weight={700}>Website</Text>
                <Text
                  color="blue"
                  className="cursor-pointer underline"
                  size={"sm"}
                  component="a"
                  href={modalData?.website}
                >
                  Access
                </Text>
              </div>
              <div className="flex flex-col items-center justify-center">
                <Text weight={700}>Status</Text>
                <Badge color={modalData?.status === 1 ? "green" : "red"}>
                  {modalData?.status === 1 ? "Active" : "Inactive"}
                </Badge>
              </div>
            </div>
            <br />
            {/* Country and division */}
            <div className="flex justify-between items-center flex-row w-full">
              <div className="flex flex-col items-center justify-center">
                <Text weight={700}>CEO VID</Text>
                <Text
                  color="blue"
                  className="underline cursor-pointer"
                  component="a"
                  href={`https://ivao.aero/member.aspx?id=${modalData?.ceoVid}`}
                >
                  {modalData?.ceoVid}
                </Text>
              </div>
              <div className="flex flex-col items-center justify-center">
                <Text weight={700}>Division</Text>
                <Text
                  size={"sm"}
                  color="blue"
                  className="underline cursor-pointer"
                  component="a"
                  href={`https://ivao.aero/divisions/division.asp?Id=${modalData?.divisionId}`}
                >
                  {modalData?.divisionId}
                </Text>
              </div>
            </div>
            <br />
          </div>
        </Modal>
        <Table verticalSpacing="lg">
          <thead>
            <tr>
              <th>Name</th>
              <th>Website</th>
              <th>ICAO</th>
              <th>IATA</th>
              <th>Division ID</th>
              <th>General Aviation?</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
      <div>
        {isFetchingNextPage ? <PulseLoader /> : (
          <Button ref={ref} disabled={!hasNextPage || isFetchingNextPage} onClick={() => fetchNextPage()} className="mb-5" color="blue" mt="sm" variant="light">
          {hasNextPage ? "Load more..." : ""}
        </Button>
        )} 
        
      </div>
    </>
  );
};

export default VirtualAirlinesTable;
