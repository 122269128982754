import "./App.scss";
import { useAuth } from "./modules/auth";
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme
} from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { CoreRoutes } from "./modules/core";
import { useState } from "react";
import { PulseLoader } from "@ivaoaero/front-components";

function App() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  const auth = useAuth();

  if (auth.isLoading) {
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <PulseLoader />
      </div>
    );
  }

  if (auth.hasRedirect()) {
    auth.redirect();
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <PulseLoader />
      </div>
    );
  }

  return (
    <>
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            colorScheme,
            colors: {
              ivao: [
                "#3C55AC",
                "#3C55AC",
                "#3C55AC",
                "#3C55AC",
                "#3C55AC",
                "#0D2C99",
                "#0D2C99",
                "#0D2C99",
                "#0D2C99",
                "#0D2C99",
              ],
            },
            fontSizes: {
              xl: 40, // h1
              lg: 32, // h2
              // md: 28, // h3
              // sm: 24, // h4
              // xs: 20, // h5
            },
          }}
        >
          <NotificationsProvider>
            <CoreRoutes />
          </NotificationsProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </>
  );
}

export default App;
