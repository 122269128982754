import { useQuery } from "react-query";
import Http from "../../../utils/Http";
import { User } from "../types/user";
import { useAuth } from "./useAuth";

const fetchUser = () => Http<User>({ url: "/v2/users/me" });

export const useUser = () => {
  const auth = useAuth();
  const { data, ...rest } = useQuery(['user'], fetchUser, {
    enabled: auth.isAuthenticated
  })

  return {
    user: <User> data ?? { id: 0, firstName: '', lastName: '' }, ...rest
  };
};
