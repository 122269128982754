import {
  PaginatedVirtualAirlineDto,
  VirtualAirlineDto,
} from "../modules/core/types/virtual-airline.type";
import Http from "./Http";

export const getPaginatedVirtualAirlines = (
  page: number
): Promise<PaginatedVirtualAirlineDto> =>
  Http<PaginatedVirtualAirlineDto>({
    url: `/v2/virtualAirlines?page=${page}`,
  }).then((items) => {
    return items;
  });

export const getAirlineLogo = (airlineId: string) => {
  return Http({
    url: `https://api.ivao.aero/v2/virtualAirlines/${airlineId}/mainLogo`,
    responseType: "blob",
  }).then((res: any) => {
    return res;
  });
};
