import React from "react";

// Component Import
import {
  Button,
  Card,
  Divider,
  Text,
  useMantineColorScheme,
} from "@mantine/core";

// Icons
import { FiExternalLink } from "react-icons/fi";
import { CgInternal } from "react-icons/cg"


interface IntroductionCardProps {
  title: string;
  description: any;
  icon: any;
}

export const IntroductionCard = ({
  title,
  description,
  icon,
}: IntroductionCardProps) => {
  const { colorScheme } = useMantineColorScheme();
  return (
    <Card shadow="md" radius="md" p="xl">
      <>{icon}</>
      <Text fz="lg" fw={500} mt="md" className="block">
        {title}
      </Text>
      <Divider size="md" color="#0d2c99" mt="md" maw="50px" />
      <Text fz="sm" mt="sm" className="max-w-600px">
        {description}
      </Text>
    </Card>
  );
};

interface ButtonObject {
  buttonText: string;
  buttonLink: string;
  internal?: boolean
}

interface CardWithButtonProps extends IntroductionCardProps {
  buttons: ButtonObject[];
}

export const IntroductionCardWithButton = ({
  title,
  description,
  buttons,
  icon
}: CardWithButtonProps) => {
  const { colorScheme } = useMantineColorScheme();
  return (
    <Card shadow="md" radius="md" p="xl">
      <>{icon}</>
      <Text fz="lg" fw={500} mt="md" className="block">
        {title}
      </Text>
      <Divider size="md" color="#0d2c99" mt="md" maw="50px" />
      <Text fz="sm" mt="sm" className="max-w-600px">
        {description}
      </Text>
      {buttons.map((button, index) => {
        return (
          <Button
            color="blue"
            mt="sm"
            component="a"
            href={button.buttonLink}
            variant="light"
            leftIcon={button.internal ? <CgInternal size="0.9rem"/> : <FiExternalLink size="0.9rem" />}
          >
            {button.buttonText}
          </Button>
        );
      })}
    </Card>
  );
};
