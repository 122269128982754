import { Slider } from "@ivaoaero/front-components";
import { Header } from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { useAuth, useUser } from "../../auth";
import CardDisplay from "../components/IntroductionCards";
import SliderImage from "../../../assets/slider.png";
import { Container, Group, SimpleGrid } from "@mantine/core";

export const Home = () => {
  const auth = useAuth();
  const { user } = useUser();
  return (
    <Container size="lg">
      <Group position="center">
        <Header />
        <Slider
          title="IVAO Flight Operations Department"
          subtitle={
            auth.isAuthenticated
              ? `Welcome ${user.firstName} ${user.lastName} (${user.id})`
              : `Welcome`
          }
          backgroundImage={SliderImage}
        />
      </Group>

      <CardDisplay />

      <Footer />
    </Container>
  );
};
