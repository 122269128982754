import { WebStorageStateStore } from "oidc-client-ts";
import { FC } from "react";
import { AuthProvider as InternalAuthProvider, AuthProviderProps } from "react-oidc-context";
import { OPENID_AUTHORITY, OPENID_CLIENT_ID, OPENID_REDIRECT_URI } from "../configuration";

const oidcConfig: AuthProviderProps = {
  authority: OPENID_AUTHORITY,
  client_id: OPENID_CLIENT_ID,
  redirect_uri: OPENID_REDIRECT_URI,
  scope: 'profile configuration',
  post_logout_redirect_uri: window.location.origin, // Or window.location.origin
  metadataSeed: {
    end_session_endpoint: window.location.origin // Or window.location.origin
  },
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  revokeTokensOnSignout: true,
}

interface Props {
  children: JSX.Element
}

export const AuthProvider: FC<Props> = ({ children }) => {
  return <InternalAuthProvider {...oidcConfig}>{children}</InternalAuthProvider>;
}
